import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/core';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import CardColumns from 'react-bootstrap/CardColumns';
import Card from 'react-bootstrap/Card';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const cardImg = css`
  &:hover {
    cursor: pointer;
  }
`;

const headerCSS = css`
  scroll-behavior: smooth;
`;

const content = css`
  min-height: ${contentHeight}vh;
`;

const Screenplays = ({ data: { prismicScreenplays } }) => {
  const getAllScreenplays = () => {
    let rows = [];
    const items = prismicScreenplays.data.body[0].items;
    const numColumns = 1000;
    // Separate for row / column card deck formatting.
    let count = 0;
    while (count < items.length) {
      rows.push([...items.slice(count, numColumns + count)]);
      count += numColumns;
    }
    return rows;
  }
  const createGallery = () => {
    const allScreenplays = getAllScreenplays();
    return allScreenplays.map((row, outerIdx) => {
      return (
        <CardColumns key={outerIdx}>
          {
            row.map((col, innerIdx) => {
              return (
                <Card className="hvr-shrink" key={innerIdx}>
                  <Link
                    to={`/screenplays/${col.screenplay_link.slug}/`}
                  >
                    <Img
                      css={cardImg}
                      fluid={col.thumbnail.localFile.childImageSharp.fluid}
                    />
                  </Link>
                  <Card.Body>
                    <Card.Title>{col.screenplay_title.text}</Card.Title>
                    <Card.Text>{col.screenplay_description.text}</Card.Text>
                  </Card.Body>
                </Card>
              );
            })
          }
        </CardColumns>
      );
    });
  }
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1 id="top" css={headerCSS}>
          {prismicScreenplays.data.title.text}
        </h1>
        {createGallery()}
      </Container>
    </Layout>
  );
};

export default Screenplays;

export const pageQuery = graphql`
  query ScreenplaysQuery {
    prismicScreenplays {
      data {
        title {
          text
        }
        body {
          id
          __typename
          ... on PrismicScreenplaysBodyScreenplayGallery {
            items {
              screenplay_title {
                text
              }
              thumbnail {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              screenplay_description {
                text
              }
              screenplay_link {
                slug
              }
            }
          }
        }
      }
    }
  }
`;
